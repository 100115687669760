import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import Video from '../../components/video'
import { Content } from '../../elements/wyswyg'
import H3 from '../../elements/typography/h3'
import P from '../../elements/typography/p'

import { breakpoint, getColor, ease } from '../../shared/theme'

const ServiceBlockWrapper = styled.div`
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 4;
	}
`

const ImgWrapper = styled.div`
	width: 100%;
	margin-bottom: 20px;
	position: relative;
	pointer-events: none;
	overflow: hidden;

	&::before {
		content: '';
		position: relative;
		width: 100%;
		display: block;
		padding-top: ${() => `${(800 / 600) * 100}%`};
	}

	@media ${breakpoint('tablet')} {
		pointer-events: all;
		margin-bottom: 36px;
		width: 100%;
	}
`

const TitleDescriptionWrapper = styled.div`
	padding: 0 20px;

	@media ${breakpoint('tablet')} {
		padding: 0 0;
	}
	margin-bottom: 18px;
`

const TitleWrapper = styled.div`
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	cursor: pointer;
`

const Title = styled(H3)`
	padding-right: 1em;

	@media ${breakpoint('desktop')} {
		font-size: 36px;
	}

	span {
		display: block;
	}
`

const Img = styled.img`
	z-index: 5;
	opacity: ${(props) => (!props.videoPlaying ? 1 : 0)};
	pointer-events: none;
	transition: all 400ms ${ease};
`

const VideoWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 101%;
`

const Icon = styled.span`
	margin-top: 0;
	display: block;
	position: relative;
	height: 20px;
	width: 20px;
	transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'none')};
	transition: transform 200ms ${ease};

	&::before,
	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background: ${getColor('black')};
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(90deg);
	}

	@media ${breakpoint('tablet')} {
		height: 24px;
		width: 24px;
		margin: 10px 20px;

		&::before,
		&::after {
			height: 3px;
		}
	}
`

const DescriptionWrapper = styled.div`
	overflow: hidden;
	max-height: ${(props) => (props.isOpen ? '800px' : '0px')};
	opacity: ${(props) => (props.isOpen ? '1' : '0')};
	visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
	transition: all ${(props) => props.theme.transitionSpeed.slow};
`

const Description = styled(Content)`
	padding-right: 0;
	width: 100%;
	margin-bottom: 22px;

	@media ${breakpoint('tablet')} {
		width: 80%;
	}
`

const ServiceBlock = ({ data }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [videoPlaying, setPlaying] = useState(false)

	const videoPlayer = useRef(null)

	const { heading, heroImage, copy, video } = data

	const titleArr = heading.split(' ')
	const cleanTitle = titleArr.map((word, index) => {
		return <span key={index}>{word}</span>
	})

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

	return (
		<ServiceBlockWrapper>
			<ImgWrapper>
				{video && (
					<VideoWrapper ref={videoPlayer}>
						<Video video={video} image={heroImage} autoplay={true} />
					</VideoWrapper>
				)}
			</ImgWrapper>
			<TitleDescriptionWrapper>
				<TitleWrapper onClick={handleClick}>
					<Title>{cleanTitle}</Title>
					<Icon isOpen={isOpen} />
				</TitleWrapper>
				<DescriptionWrapper isOpen={isOpen}>
					<Description dangerouslySetInnerHTML={{ __html: copy }} />
				</DescriptionWrapper>
			</TitleDescriptionWrapper>
		</ServiceBlockWrapper>
	)
}

export default ServiceBlock
