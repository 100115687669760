import React from 'react'
import SEO from '../../components/SEO'
import PageHeaderImage from '../../components/pageHeaderImage'
import PageIntroText from '../../components/pageHeaderIntro'
import Services from '../../components/services'
import InviewWrapper from '../../elements/inviewWrapper'

const ProcessPage = ({ pageContext }) => {
	const {
		page: { title, default_page_header: pageHeader, ourProcesses: services },
	} = pageContext

	return (
		<>
			<SEO title={title} />
			<PageHeaderImage content={pageHeader} />
			<InviewWrapper>
				<PageIntroText content={pageHeader} />
				<Services services={services} />
			</InviewWrapper>
		</>
	)
}

export default ProcessPage
