import React from 'react'
import styled from 'styled-components'
import ServiceBlock from '../../components/serviceBlock'
import { breakpoint } from '../../shared/theme'
import { Wrapper } from '../../elements/innerWrapper'
import Grid from '../../elements/grid'
import SectionTransition from '../../elements/activeSectionDefault'

const ServicesWrapper = styled(SectionTransition)`
	padding: 0 0 100px;

	@media ${breakpoint('tablet')} {
		padding: 32px 0 200px;
	}
`

const ServiceBlockWrapper = styled(Wrapper)`
	width: 100%;

	@media (max-width: 889px) {
		padding: 0 0;
	}
`

const Services = ({ services, isActive }) => {
	const { processes } = services
	if (!services || services == null || services.length <= 0) {
		return null
	}

	return (
		<ServicesWrapper isActive={isActive}>
			<ServiceBlockWrapper>
				<Grid desktopColumns={12} columnDesktopGap="24px">
					{processes.map((item, index) => (
						<ServiceBlock data={item} key={index} />
					))}
				</Grid>
			</ServiceBlockWrapper>
		</ServicesWrapper>
	)
}

export default Services
